
let vendor, solution, searchCountries;
let shadowRoot = document;

let service, geocoder, timer;
let selectionList = [];
const config = {
    attributes: true,
};
let oldAttributeValue = 'false';
let selectionBounds;

const initaliseMaps = async () => {
    
    searchCountries = 'DE';
    console.log('Searching limited to: ', searchCountries);

    const mapsGmapsInvoke = document.createElement('script');

    try {
       
        mapsGmapsInvoke.setAttribute(
            'src',
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyBEhbOiwQylvv5yYxo9km9HCfy_zScPXSg&libraries=places&language=de`,
        );

        document.body.appendChild(mapsGmapsInvoke);
    } catch (error) {
        console.error('Error loading Google Maps', error);
    }
};

// Assign maps to the flow and wait for the maps to assign its service in the flow.
const assignMaps = () => {
    if (typeof google === 'object' && typeof google.maps === 'object') {
        console.info('Maps Loaded');
        service = new google.maps.places.AutocompleteService();
        geocoder = new google.maps.Geocoder();
        clearInterval(timer);
    } else {
        console.info('Maps not yet loaded.');
    }
};

const getFormattedAddress = async (selectionAddressId) => {
    try {
        const results = await geocodePlaceId(selectionAddressId);

        if (results[0]) {
            return results[0];
        }
    } catch (error) {
        console.error('Error fetching Zip Code', error);
    }
};

const geocodePlaceId = (placeId) => {
    return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            placeId
        }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                resolve(results);
            } else {
                console.error('Geocode error:', status);
            }
        });
    });
};

// Reverse Geocode from Lat and Lang based on teh marker drag from the showcased Maps.
const reverseGeocode = (latLng) => {
    return new Promise((resolve,
        reject) => {
        geocoder.geocode(
            {
                location: latLng,
            },
            (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(results);
                } else {
                    console.error('Geocode error:', status);
                }
            },
        );
    });
};

const getAddressElement = (element, formatted_address) => {
    let filteredAddressElement = formatted_address.filter((item) =>
        item.types.includes(element),
    );

    if (filteredAddressElement.length > 0) {
        return filteredAddressElement[0].long_name;
    } else return '';
};

const fillDetails = (variable, addressElement) => {
    shadowRoot.querySelector(`[data-variable="${variable}"]`).value =
    addressElement;
};

const addAutocomplete = (field, restrictionTypes) => {
    field.heyflowOnAutocomplete = async (text, update) => {
        await updateAutocompleteList(field, text, update, service, restrictionTypes);
    };
};

const updateAutocompleteList = async (
    field,
    searchText,
    update,
    service,
    restrictionsList,
) => {
    if (searchText.trim() === '') {
        update([]);
        return;
    }
    try {
        // console.log(postalCodeSelected);
        service.getPlacePredictions(
            {
                input: searchText,
                componentRestrictions: {
                    country: searchCountries.split(',')
                },
                types: restrictionsList,
            },
            async (predictions, status) => {
                if (
                    status !== google.maps.places.PlacesServiceStatus.OK ||
                    !predictions
                ) {
                    console.error('Autocomplete error:', status);
                    field.value = '';
                    return;
                }

                const items = [];
                selectionList = [];
                for (const prediction of predictions) {
                    try {
                        items.push({
                            label: prediction.description,
                        });
                        selectionList.push({
                            id: prediction.place_id,
                            address: prediction.description,
                        });
                    } catch (error) {
                        console.error('Error fetching Zip Code', error);
                    }
                }
                update(items);
            },
        );
    } catch (error) {
        console.error('Error Generating predictions',
            error);
    }
};

// After Maps is loaded, start the components for maps services
const onLoadCallback = async () => {
    // Initialise maps with proper key for customer
    initaliseMaps();

    // Autocomplete for WP with only PLZ as responses.
    const inputField = shadowRoot.querySelector(
        '[data-variable="mapsNativeSelect"]',
    );

    console.info('Input field',
        inputField);
    addAutocomplete(inputField, ['postal_code']);

    timer = setInterval(assignMaps, 1000);
};

window.addEventListener('heyflow-init', async () => {
    console.info('Heyflow Initialising');

    // For Non-embed
    await onLoadCallback();
});

// Prefill input field for href
function setFullUrl() {
    const fullurl = document.querySelector('[data-label="fullurl"]');
    if (fullurl) {
        fullurl.value = window.location.href;
    } else {
        setTimeout(setFullUrl, 1000);
    }
}

setFullUrl();